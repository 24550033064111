<template>
  <div class="container">
    <div class="row justify-content-center" id="container-finalizacao" v-if="processoPendente">
      <div class="col-6">
        <div class="shadow-sm bg-white shadow-hover panel card-panel">
          <div class="col">
            <div class="text-center">
              <i class="fas fa-circle-notch fa-spin fa-3x i-primary"></i>
              <p class="title pt-2">Aguarde, finalizando a devolução...</p>
            </div>

            <div class="row">
              <passo-finalizacao
                descricao="Nota de devolução emitida com sucesso!"
                :sucesso="true"
                v-show="passos.emissao.sucesso"
              />
              <passo-finalizacao descricao="Falha ao emitir a nota de devolução." :falha="true" v-show="passos.emissao.falha" />
              <passo-finalizacao descricao="Emitindo nota de devolução..." :pendente="true" v-show="passos.emissao.pendente" />

              <passo-finalizacao
                descricao="Nota autorizada com sucesso na SEFAZ!"
                :sucesso="true"
                v-show="passos.autorizacao.sucesso"
              />
              <passo-finalizacao
                descricao="Falha ao autorizar a nota na SEFAZ."
                :falha="true"
                v-show="passos.autorizacao.falha"
              />
              <passo-finalizacao
                descricao="Autorizando a nota na SEFAZ...."
                :pendente="true"
                v-show="passos.autorizacao.pendente"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" id="container-finalizacao" v-else>
      <div class="col-6">
        <finalizacao-resumo v-if="passos.autorizacao.sucesso" :NotaGerada="finalizacaoResumo" />

        <finalizacao-falha v-if="passos.emissao.falha" :MensagensErro="finalizacaoFalhas" />

        <emissao-rejeitado v-if="passos.autorizacao.falha" :Rejeicao="emissaoRejeicao" :DocumentoGerado="documentoGerado" />

        <div class="row">
          <div class="col-12 pb-1">
            <button id="button-realizar-nova-devolucao" class="btn btn-primary btn-block" @click="novaDevolucao()"
              >Realizar Nova Devolução</button
            >
          </div>

          <div id="button-tentar-novamente" class="col-12 pb-1" v-if="passos.emissao.falha || passos.autorizacao.falha">
            <button class="btn btn-default btn-block" @click="tentarNovamente()">Tentar novamente</button>
          </div>

          <div class="col-12 pb-1">
            <documento-interno v-if="passos.emissao.sucesso" :identificador="this.finalizacao.Id" />
          </div>

          <div class="col-12">
            <emissao-autorizado v-if="passos.autorizacao.sucesso" :notaGerada="notaAutorizadaAcoes" />
          </div>
          <div class="col-12 text-center">
            <span
              >Nesta operação foram geradas as notas: <strong> {{ documentosGerados }} </strong>. Para visualizar as notas
              <a @click="showNotasGeradas = true" href="#">Clique Aqui</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <modal-finalizacao-resumo-notas-geradas
      :NotasGeradas="this.finalizacao.Devolucoes"
      v-if="showNotasGeradas"
      @fechar="showNotasGeradas = false"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import numeral from 'numeral';
  import 'numeral/locales/pt-br';
  import NFeService from '@/core/services/nfe.service';
  import FinalizacaoResumo from '@/components/shared/finalizacao/FinalizacaoResumo.vue';
  import ModalFinalizacaoResumoNotasGeradas from '@/components/shared/finalizacao/FinalizacaoResumoNotasGeradas.vue';
  import FinalizacaoFalha from '@/components/shared/finalizacao/FinalizacaoFalha.vue';
  import EmissaoAutorizado from '@/components/shared/finalizacao/EmissaoAutorizado.vue';
  import EmissaoRejeitado from '@/components/shared/finalizacao/EmissaoRejeitado.vue';
  import PassoFinalizacao from '@/components/shared/finalizacao/PassoFinalizacao.vue';
  import DocumentoInterno from '@/components/shared/input/DocumentoInterno.vue';
  import Axios from 'axios';
  import apiConfig from '@/services/apiConfig';
  import apiClient from '@/services/apiClient';

  numeral.locale('pt-br');

  export default {
    name: 'DevolucaoFinalizacao',
    data() {
      return {
        passos: {
          emissao: { pendente: true, sucesso: false, falha: false },
          autorizacao: { pendente: false, sucesso: false, falha: false },
        },
        emissaoRejeicao: null,
        finalizacaoFalhas: [],
        showNotasGeradas: false,
      };
    },
    components: {
      FinalizacaoResumo,
      ModalFinalizacaoResumoNotasGeradas,
      FinalizacaoFalha,
      EmissaoAutorizado,
      EmissaoRejeitado,
      PassoFinalizacao,
      DocumentoInterno,
    },
    filters: {
      monetario(value) {
        return numeral(value).format('$0,0.00');
      },
    },
    computed: {
      ...mapState({
        fornecedor: (state) => state.devolucao.Identificacao.Fornecedor,
        naturezaOperacao: (state) => state.devolucao.Conferencia.NaturezaOperacao,
        finalizacao: (state) => state.devolucao.Finalizacao,
        autorizacao: (state) => state.devolucao.Autorizacao,
      }),
      ...mapGetters({
        emitirNotasPorChaveOrigem: 'devolucao/emitirNotasPorChaveOrigem',
      }),
      processoPendente() {
        return Object.values(this.passos).some((passo) => passo.pendente);
      },
      finalizacaoResumo() {
        if (!this.finalizacao) return;

        return {
          DocumentoGerado: this.documentoGerado,
          TotalNota: this.finalizacao.ValorTotal,
          Cliente: `(${this.fornecedor.Codigo}) ${this.fornecedor.Nome}`,
          NaturezaOperacao: `(${this.naturezaOperacao.Id}) ${this.naturezaOperacao.Descricao}`,
        };
      },
      documentoGerado() {
        if (!this.finalizacao || !this.finalizacao.Documento || this.passos.autorizacao.falha) return;

        return `${this.finalizacao.Documento}/${this.finalizacao.Serie}`;
      },
      documentosGerados() {
        if (!this.finalizacao || !this.finalizacao.Documento) return;
        let documentos = this.finalizacao.Devolucoes.map((dev) => {
          return dev.Documento;
        }).join(', ');
        return `${documentos}`;
      },
      notaAutorizadaAcoes() {
        if (!this.autorizacao?.IdNFe) return;

        return {
          Id: this.finalizacao.Id,
          EmImagem: false,
          IdNFe: this.autorizacao.IdNFe,
          Chave: this.autorizacao.Chave,
          EmailCliente: this.fornecedor.Email,
        };
      },
    },
    watch: {
      'passos.emissao.pendente': {
        handler(newValue) {
          if (!newValue) return;
          let finalizado = this.finalizacao.Id != null;

          if (finalizado) {
            this.$nextTick(() => this.atualizarPasso(this.passos.emissao, 'sucesso'));
            return;
          }

          this.finalizar();
        },
        immediate: true,
      },
      'passos.emissao.sucesso'(newValue) {
        if (!newValue) return;

        this.atualizarPasso(this.passos.autorizacao, 'pendente');
      },
      'passos.autorizacao.pendente'(newValue) {
        if (!newValue) return;

        if (this.autorizacao.IdNFe) {
          this.atualizarPasso(this.passos.autorizacao, 'sucesso');
          return;
        }

        this.autorizar();
      },
      'passos.autorizacao.sucesso'(newValue) {
        if (!newValue) return;
      },
    },
    methods: {
      autorizar() {
        let autorizacao = this.finalizacao.SituacaoAutorizacao?.Autorizacao;
        if (autorizacao) this.$store.dispatch('devolucao/atualizarNotaAutorizada', { ...autorizacao, IdNFe: autorizacao.Id });
        let notasAutorizacao = [];
        this.finalizacao.Devolucoes.forEach((devolucao) => {
          if (devolucao.SituacaoAutorizacao.Sucesso) {
            notasAutorizacao.push({ Passo: 'sucesso' });
          } else {
            notasAutorizacao.push({ Passo: 'falha', Rejeicao: devolucao.SituacaoAutorizacao.Rejeicao });
          }
        });
        if (notasAutorizacao.some((devolucao) => devolucao.Passo == 'falha')) {
          let _rejeicao = notasAutorizacao.filter((dev) => dev.Passo == 'falha')[0]?.Rejeicao;
          this.emissaoRejeicao = _rejeicao;
          this.atualizarPasso(this.passos.autorizacao, 'falha');
        } else {
          this.atualizarPasso(this.passos.autorizacao, 'sucesso');
        }
      },
      finalizar() {
        this.$store
          .dispatch('devolucao/finalizar')
          .then(() => {
            this.atualizarPasso(this.passos.emissao, 'sucesso');
          })
          .catch((falhas) => {
            this.finalizacaoFalhas = falhas;
            this.atualizarPasso(this.passos.emissao, 'falha');
          });
      },

      atualizarPasso(passo, funcao) {
        Object.keys(passo).forEach((v) => (passo[v] = false));
        this.$set(passo, funcao, true);
      },

      tentarNovamente() {
        location.reload();
      },

      novaDevolucao() {
        this.$store.dispatch('devolucao/iniciarNovaDevolucao');
        this.$router.push({ name: 'DevolucaoIdentificacao' });
      },
    },
    mounted() {},
  };
</script>

<style scoped></style>
