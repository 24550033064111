<template>
  <div class="div">

    <BaseModal
      @close="fechar()"
      :telaInteira="false"
      :tituloModal="'Devolução de Compra Fácil'"
      :classesAdicionaisModal="'rounded modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2'"
      :classesAdicionaisFooter="'p-0'"
      :classesAdicionaisBody="'p-0'"
    >
      <div slot="body" class="bg-dark">
        <div class="shadow-sm bg-white shadow-hover panel card-panel scroll">
          <div class="row justify-content-center" id="notasFiscaisEmitidas">
            <div class="col-12 mb-1 mt-1">
              <p class="font-weight-bold">Notas Fiscais Emitidas</p>
            </div>
            <div class="col-12">
              <div class="table-responsive mb-3">
                <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
                  <thead>
                    <tr>
                      <th class="text-left text-nowrap"></th>
                      <th class="text-center align-middle">Número NF</th>
                      <th class="text-center align-middle">Série</th>
                      <th class="text-center align-middle">Valor Total</th>
                      <th class="text-center align-middle">Fornecedor</th>
                      <th class="text-center align-middle">Natureza de Operação</th>
                      <th class="text-center align-middle text-nowrap">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(nota, index) in listaNotasGeradas" :key="index">
                      <th class="text-left text-nowrap checkbox-column">
                        <input
                          type="checkbox"
                          id="item.CodigoProduto"
                          :disabled="!nota.IdNFe"
                          :checked="nota.Selecionado"
                          @change="selecionarItem($event.target.checked, nota)"
                        />
                      </th>
                      <td class="text-center">
                        <button
                          class="btn btn-sm btn-danger mr-1"
                          v-if="(!nota.IdNFe && nota.Mensagem)"
                          v-tooltip.bottom="tooltipStyle(`${nota.Mensagem}`)"
                          id="devolucao-notas-button-documento-interno"
                        >
                        <i class="fa fa-exclamation"></i>
                        </button>
                        {{ nota.Documento }}
                      </td>
                      <td class="text-center">
                        {{ nota.Serie }}
                      </td>
                      <td class="text-center text-nowrap">
                        {{ nota.ValorTotal | money }}
                      </td>
                      <td class="text-center"> {{ nota.NomeFornecedor }} ({{ nota.IdFornecedor }}) </td>
                      <td class="text-center"> {{ nota.DescricaoNaturezaOperacao }} ({{ nota.CodigoNaturezaOperacao }}) </td>
                      <td class="text-center align-middle text-nowrap">
                        <button
                          class="btn btn-sm btn-primary mr-1"
                          :disabled="!nota.IdNFe"
                          @click="imprimirDanfe(nota.IdNFe)"
                          v-tooltip.bottom="tooltipStyle('Imprimir Danfe')"
                          id="devolucao-notas-button-imprimir-danfe"
                        >
                          <i class="fas fa-print fa-fw"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-primary mr-1"
                          @click="abrirDocumentoInterno(nota.Id)"
                          v-tooltip.bottom="tooltipStyle('Documento Interno')"
                          id="devolucao-notas-button-documento-interno"
                        >
                          <i class="fas fa-file-alt"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-primary mr-1"
                          :disabled="nota.IdNFe"
                          @click="reautorizar(nota)"
                          v-tooltip.bottom="tooltipStyle('Reautorizar Nota')"
                          id="devolucao-notas-button-documento-interno"
                        >
                          <i class="fa fa-sync-alt"></i>
                        </button>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="modal-footer pr-4 bg-white w-100 border">
          <div class="w-100 d-flex justify-content-between">
            <button
              id="lmxta-finalizacaoNFCe-btn-enviaremail"
              class="btn btn-secondary"
              :disabled="!habilitaEnvioDanfes"
              @click="StatusEmail.Formulario = true"
            >
              Enviar DANFE por E-mail
              <i class="far fa-paper-plane fa-fw"></i>
            </button>
            <div class="d-flex">
              <button id="button-gerar-excel-notas-geradas" class="btn btn-default mr-1" @click="imprimirRelatorio"> Imprimir </button>
              <button id="button-gerar-excel-notas-geradas" class="btn btn-default ml-1" @click="exportarCSV"> Gerar Excel </button>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
    <envio-email-danfe
      :nfce="false"
      :listIdNFe="listaIdsNFeEmail"
      v-if="StatusEmail.Formulario"
      @fechar="StatusEmail.Formulario = false"
    />
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapGetters, mapState } from 'vuex';
  import loading from '@/common/loading';
  import swal from '@/common/alerts';
  import NFeService from '@/core/services/nfe.service';
  import EnvioEmailDanfe from '@/components/shared/finalizacao/EnvioEmailDanfe.vue';
  import { baixarArquivoPdf } from '@/common/files';
  import dateUtils from '@/common/date';
  import VueHtmlToPaper from 'vue-html-to-paper';
  Vue.use(VueHtmlToPaper);

  export default {
    name: 'FinalizacaoResumoNotasGeradas',
    props: {
      NotasGeradas: Array,
    },
    components:{
      EnvioEmailDanfe,
    },
    data() {
      return {
        listaNotasGeradas: null,
        StatusEmail: {
          Enviando: false,
          Formulario: false,
          Valido: true,
          Dica: false,
        },
        StatusDanfe: {
          Baixando: false,
        },
        NFeService: NFeService,
      }
    },
    computed: {
      ...mapGetters('devolucao', ['baseUrlErp']),
      ...mapState({
        fornecedor: (state) => state.devolucao.Identificacao.Fornecedor,
      }),
      habilitaEnvioDanfes(){
        return this.listaNotasGeradas?.some((nota) => nota.Selecionado);
      },
      listaIdsNFeEmail() {
        let listaIds = this.listaNotasGeradas.filter((nota) => nota.Selecionado);
        return listaIds.map((m) => {
          return m.IdNFe;
        });
      }
    },
    mounted() {
      this.listaNotasGeradas = this.NotasGeradas.map((nota) => {
        let notaAutorizada = nota.SituacaoAutorizacao?.Autorizacao;
        let rejeicao = nota.SituacaoAutorizacao?.Rejeicao;
        return {
          ...nota,
          Selecionado: false,
          IdNFe: notaAutorizada && notaAutorizada.Id,
          Chave: notaAutorizada && notaAutorizada.Chave,
          EmailCliente: this.fornecedor.Email,
          Mensagem: rejeicao && rejeicao.Mensagem,
        };
      });
    },
    methods: {
      selecionarItem(selecionado, nota) {
        var idx_nota;
        var notaExistente = this.listaNotasGeradas.find((n, indice) => {
          idx_nota = indice;
          return n.Id == nota.Id;
        });

        notaExistente.Selecionado = selecionado;
        this.listaNotasGeradas.splice(idx_nota, 1, notaExistente);
      },
      reautorizar(nota){
        let request = { Id: nota.Id };
        loading.exibir();
        NFeService.autorizar(request)
          .then((response) => {

            this.$store.dispatch('devolucao/atualizarNotasAutorizadas', Object.assign(nota, response.data));
          })
          .catch((rejeicao) => {
            this.$store.dispatch('devolucao/atualizarNotasAutorizadas', Object.assign(nota, rejeicao));
            let msg = rejeicao.Mensagem;
            swal.exibirMensagemErro(`Ocorreu um problema ao reautorizar a nota: ${msg}`);
          }).finally(() => loading.ocultar());
      },

      abrirDocumentoInterno(identificador) {
        let url = this.baseUrlErp + '/faturamento/imprime_doc.asp?identificador=' + identificador;
        window.open(url, '_blank');
      },

      imprimirDanfe(identificador) {
        this.StatusDanfe.Baixando = true;
        this.NFeService.imprimirDanfe({ IdNFe: identificador })
          .then((result) => {
            baixarArquivoPdf(result, identificador, true);
          })
          .catch((error) => {
            swal.exibirMensagemErro('Ocorreu um problema ao imprimir.');
          })
          .finally(() => (this.StatusDanfe.Baixando = false));
      },

      fechar() {
        this.$emit('fechar');
      },

      exportarCSV: function () {
        const nomeArquivo = `DevoluçãoCompraFacil_NotasEmititdas.xls`;
        var table = `<h2>Devolução Compra Fácil</h2>
                      <h3>Notas FiscaisEmitidas</h3>`;

        if (this.NotasGeradas.length > 0) {
          const linhas = this.NotasGeradas.map((i) => {
            return `
            <tr>
              <td>${i.Documento}</td>
              <td>${i.Serie}</td>
              <td>R$ ${i.ValorTotal}</td>
              <td class="text-center"> ${i.NomeFornecedor} (${i.IdFornecedor}) </td>
              <td class="text-center"> ${i.DescricaoNaturezaOperacao} (${i.CodigoNaturezaOperacao}) </td>
            </tr>`;
          }).join('');

          table = `${table}
          <table>
            <thead>
              <tr>
                <th>Número NF</th>
                <th>Série</th>
                <th>Valor Total</th>
                <th>Fornecedor</th>
                <th>Natureza de Operação</th>
              </tr>
            <thead>
            <tbody>${linhas}</tbody>
          </table>`;
        }
        this.download(table, nomeArquivo);
        return Promise.resolve();
      },

      formatarDataPTBR(data) {
        if (data != '') return dateUtils.toStringPtBr(data);
      },

      download(body, nomeArquivo, nomeAba) {
        nomeAba = nomeAba || 'Relatório';
        var tableContent = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
          <head> 
            <!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${nomeAba}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
            <meta charset="UTF-8"/>
            <style>.int{ mso-number-format:"0";}</style>
          </head>
          <body>${body}</body>
          </html>`;
        if (window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([tableContent], {
            type: 'application/vnd.ms-excel',
          });
          window.navigator.msSaveBlob(blob, nomeArquivo);
        } else {
          let blob = new Blob([tableContent], {
            type: 'application/vnd.ms-excel',
          });
          var elem = window.document.createElement('a');
          const data = window.URL.createObjectURL(blob);
          elem.href = data;
          elem.download = nomeArquivo;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },

      imprimirRelatorio() {
        const options = {
          name: '_blank',
          specs: ['fullscreen=yes', 'titlebar=no', 'scrollbars=yes'],
          timeout: 1000, // default timeout before the print window appears
          autoClose: true, // if false, the window will not close after printing
          windowTitle: window.document.title, // override the window title
        };
        this.$htmlToPaper('notasFiscaisEmitidas',options);
      },

      tooltipStyle(content, classPersonalizada) {
        return {
          content,
          template: `
					<div class="tooltip-vue" role="tooltip">
						<div class="tooltip-vue-arrow ${classPersonalizada && classPersonalizada.arrowStyle}"></div>
						<div class="tooltip-vue-inner ${
              classPersonalizada && classPersonalizada.innerStyle
            }" style="border-radius: 10px; font-size: 0.8rem"></div>
					</div>
				`,
          arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
          innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
        };
      },
    },
  };
</script>

<style>
</style>
